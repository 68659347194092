import React, { useState, useEffect } from 'react'
import '../Navbar/Navbar.css'
import logo from '../../images/logo.svg'
import toggler from '../../images/navbartogglericon.svg'
import closebutton from '../../images/closecross.svg'
import support from '../../images/support.svg'
import medical from '../../images/medical.svg'
import API from '../../images/API.svg'
import digital from '../../images/digital.png'
import AR from '../../images/AR.svg'
import arrowhead from '../../images/Vector.svg'
import { useLocation } from 'react-router-dom'
const Navbar = () => {
    const [marginTop, setmarginTop] = useState('-55vh')

    const [bottom, setbottom] = useState('-17%')
    const [showDiscoveriesDropdown, setShowDiscoveriesDropdown] = useState(false);

    const handlePull = () => {
        setmarginTop('0%')
    }

    const handlePush = () => {
        setmarginTop('-55vh')
        setShowDiscoveriesDropdown(false)
        setbottom('-17%')
    }

    const handleClick = () => {
        setShowDiscoveriesDropdown(!showDiscoveriesDropdown);
        if (!showDiscoveriesDropdown) {
            setbottom('-10%')
        }
        else {
            setbottom('-17%')
        }
    }
    // const scroll = (sectionId) => {
    //     const section = document.getElementById(sectionId);
    //     if (section) {
    //         section.scrollIntoView({ behavior: 'smooth' })
    //     }
    // }
    const location = useLocation();

    const scrollToSection = () => {
        if (location.hash) {
            const stringWithoutHash = location.hash.substring(1); // Remove the "#" character
            const element = document.getElementById(stringWithoutHash);
    
            if (element) {
                element.scrollIntoView({ behavior: 'smooth', block: 'start', inline: 'start' });
            }
        }
    };
    
    // Use the window.onload event to wait for the entire page to load
    window.onload = scrollToSection;
    
    // If the page is already loaded, the event may have already fired, so call scrollToSection directly
    if (document.readyState === 'complete') {
        scrollToSection();
    }
    

    return (
        <>
            <nav className="navbar navbar-expand-lg bg-body-tertiary">
                <div className="container-fluid">
                    <a className="navbar-brand" href="/"><img src={logo} alt='' /></a>
                    <button className="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarNavAltMarkup" aria-controls="navbarNavAltMarkup" aria-expanded="false" aria-label="Toggle navigation">
                        <span className="navbar-toggler-icon"></span>
                    </button>
                    <div className="collapse navbar-collapse" id="navbarNavAltMarkup">
                        <div className="navbar-nav">
                            <a className="nav-link active" aria-current="page" href="/">Home</a>
                            <li class="nav-item dropdown">
                                <a class="nav-link dropdown-toggle" href="#" id="navbarDropdown" role="button" data-bs-toggle="dropdown" aria-haspopup="true" aria-expanded="true">
                                    Discoveries &nbsp;<img src={arrowhead} alt='' />
                                </a>
                                <div class="dropdown-menu" aria-labelledby="navbarDropdown">
                                    <div className='dropdownmain'>
                                        <a className="dropdown-item" id='callwisenav' href="/#Callwise" style={{ cursor: 'pointer' }}><img src={support} alt='' />Callwise</a>
                                        <a class="dropdown-item" href="/#Medical" style={{ cursor: 'pointer' }}><img src={medical} alt='' />Medical Diagnostics</a>
                                        <a class="dropdown-item" href="/#QuickDetect" style={{ cursor: 'pointer' }}><img src={API} alt='' />Quick Detect</a>
                                        <a class="dropdown-item" href="/#Agriculture" style={{ cursor: 'pointer' }}><img src={digital} alt='' />Agri-Revolution</a>
                                        <a class="dropdown-item" id='ARnav' href="/#AR" style={{ cursor: 'pointer' }}><img src={AR} alt='' />AR Books</a>
                                    </div>
                                </div>
                            </li>
                            <a className="nav-link" href="/#Contact" data-bs-target='/#Contact' style={{ cursor: 'pointer' }}>Get in Touch</a>
                        </div>
                    </div>
                </div>
            </nav>

            <div className='mobile-nav'>
                <div className="header-navbar">
                    <div className="navheader-image" style={{ bottom: bottom }}>
                        <a href="/"> <img src={logo} alt='' /></a>
                    </div>
                    <div className="header-toggler">
                        <img src={toggler} onClick={handlePull} alt='' />
                    </div>
                </div>
                <div className='menu-navbar' style={{ top: marginTop }}>
                    <div className='navbarcross'><img src={closebutton} onClick={handlePush} alt='' /></div>
                    <div className="dropdownitems">Home</div>
                    <div className="dropdownitems">
                        <span onClick={handleClick}>Discoveries &nbsp;<img src={arrowhead} alt='' /></span>
                        {showDiscoveriesDropdown && (
                            <div className="discoveries-dropdown">
                                <a href="/#Callwise" data-bs-target='/#Callwise'>Callwise</a>
                                <a href="/#Medical" data-bs-target='/#Medical'>Medical Diagnostics</a>
                                <a href="/#QuickDetect" data-bs-target='/#QuickDetect'>Quick Detect</a>
                                <a href="/#Agriculture" data-bs-target='/#Agriculture'>Agri-Revolution</a>
                                <a href="/#AR" data-bs-target='/#AR'>AR Books</a>
                            </div>
                        )}
                    </div>
                    <div className="dropdownitems"><a href='/#Contact' data-bs-target='/#Contact' style={{textDecoration: 'none', color:'white'}}>Get in Touch</a></div>
                    <div className="navmenu-image" style={{ bottom: bottom }}>
                        <a href="/"> <img src={logo} alt='' /></a>
                    </div>
                </div>
            </div>
        </>
    )
}

export default Navbar
