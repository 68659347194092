import React from 'react'
import './Privacy.css'
const Terms = () => {
    return (
        <>
            <div className='privacy-banner'>
                <div className='privacy-heading'>
                    <h1> TERMS & CONDITIONS </h1>
                </div>

                <p>Welcome to IDRAK AI, a registered entity, having its registered office at 71-75 Shelton Street Covent Garden, London WC2H 9JQ, United Kingdom</p>

                <h3>1. Acceptance of Terms</h3>
                <p>By signing up or registering with IDRAK AI, you acknowledge and agree that the information provided during the sign-up process, including personal and contact details, may be used by IDRAK AI <a href='https://idrakai.com/'>(“idrakai.com")</a> for the purpose of delivering the services you have requested,  you agree to comply with and be bound by these Terms of Use ("Terms"). If you disagree with these Terms, please do not use the Website.
                </p>


                <h3>2. Changes to Terms</h3>
                <p>IDRAK AI reserves the right to modify or revise these Terms at any time without notice. Your continued use of the Website after any changes indicates your acceptance of those changes.</p>

                <h3>3. Use of the Website</h3>
                <p>- You must be at least 18 years old to use this Website or have the consent of a legal guardian.</p>
                <p>- You are responsible for maintaining the confidentiality of your account and password, if applicable.</p>
                <p>- You agree to use the Website for lawful purposes only and not for any unlawful or prohibited activities.</p>
                <p>- You agree not to interfere with the proper operation of the Website or attempt to gain unauthorized access to any portion of the Website.</p>

                <h3>4. Intellectual Property</h3>
                <p>All content, logos, trademarks, and materials on the Website are owned or licensed by IDRAK AI and are protected by intellectual property laws.</p>
                <p>You may not use, reproduce, distribute, or modify any content from the Website without prior written consent from IDRAKAI.</p>


                <h3>5. User-Generated Content</h3>
                <p>Suppose you submit any content to the Website. In that case, you grant IDRAK AI a non-exclusive, royalty-free, worldwide, perpetual, and irrevocable right to use, modify, and distribute such content for any purpose.</p>
                <p>You are solely responsible for the content you submit and agree not to submit any content that is illegal, defamatory, or infringing on the rights of any third party.</p>


                <h3>6. Privacy</h3>
                <p>We are committed to safeguarding your information and adhere to applicable data protection and privacy laws. For a more detailed understanding of how your information is collected, processed, and protected, please refer to our <a href="/Privacy">Privacy Policy</a></p>


                <h3>7. Disclaimer</h3>
                <p>The Website and its content are provided "as is" and "as available" without any warranties, express or implied.</p>
                <p>IDRAK AI makes no representations or warranties regarding the accuracy, completeness, or reliability of the content on the Website.</p>


                <h3>8. Limitation of Liability</h3>
                <p>To the fullest extent permitted by law, IDRAK AI shall not be liable for any direct, indirect, incidental, special, consequential, or punitive damages, or any loss of profits or revenues, whether incurred directly or indirectly, arising from your use of the Website.</p>


                <h3>9. Governing Law</h3>
                <p>These Terms are governed by and construed in accordance with provisions of Rule 3 of the Information Technology (Intermediaries guidelines) Rules, 2011 that require publishing of the Rules and Regulations, Privacy Policy, and Terms and Conditions for access or usage of Websites without regard to its conflict of law principles.</p>

                <h3>10. Contact Information</h3>
                <p>If you have any questions or concerns about these Terms, please contact us at:</p>
                <a href="/Home">IDRAK AI</a>
                <p><a href="https://goo.gl/maps/it7VmfaJVet1JdR18">71-75 Shelton Street Covent Garden, London WC2H 9JQ, United Kingdom</a></p>
                <a href="mailto:info@idrakai.com">info@idrakai.com</a>
                <p><a href="tel:+447771743077">+447771743077</a></p>
                <h3>11. Entire Agreement</h3>
                <p>These Terms constitute the entire agreement between you and IDRAK AI regarding your use of the Website, superseding any prior agreements or understandings.</p>
            </div>

            <div className='bottom-bar'>
                <a href='/'>@idrak.ai</a>
                <a href='/Privacy'>Privacy Policy</a>
                <a href='/Terms'>Terms of Use</a>
            </div>
        </>
    )
}

export default Terms
