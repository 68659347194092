import React, { useEffect, useRef } from 'react'
import './Agri.css'
import agriculture from '../../images/Videos/agri.m4v'
import embodiesbg from '../../images/pattern.svg'
import agribg from '../../images/agri-bg.svg'
import agrimob from '../../images/agri-bgmobile.png'
import bullet from '../../images/Bullet.svg'
import arrow from '../../images/agri-arrows.svg'
const Agri = () => {
    const vidRef = useRef(null);
  
    useEffect(() => {
      const video = vidRef.current;
  
      // Autoplay the video in a loop
      video.play();
      video.addEventListener('ended', () => {
        video.currentTime = 0;
        video.play();
      });
    }, []);
  return (
    <>
      <div className="agriculture-wrapper" id='Agriculture'>
        <img src={agribg} className='agri-bg' />
        <img src={agrimob} className='agri-bg-mob' />
        {/* <img src={embodiesbg} className='patternbg' /> */}
        <video 
          ref={vidRef}
          src={agriculture}
          muted
          controls={false}
          loop
          playsInline
        />
        <div class="vignette"></div>

        <div className="inner-agriculture">
          <div className='agriculture-heading'>
            <h1>Agri-Revolution</h1>
          </div>
          <div className="agri-subsections">
            <div className='agri-embodies'>
              <h2>Symbiosis of Tech & Terra</h2>
              <h2>Future Focused Farming</h2>
            </div>
          </div>
          <div className="agri-content-bullets-main">
            <img src={arrow} />
            <div className='agri-context-bullets'>
              <div className="bullets">
                <img src={bullet} />
                <p>More Green, Less Guess.</p>
              </div>
              <div className="bullets">
                <img src={bullet} />
                <p>Less Work, More Yield.</p>
              </div>
              <div className="bullets">
                <img src={bullet} />
                <p>Sustainable Farming</p>
              </div>
              <div className="bullets">
                <img src={bullet} />
                <p>Hands-Free Harvest.</p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  )
}

export default Agri
