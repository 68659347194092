import React from 'react'
import { useState, useRef } from 'react'
import './Contact.css'
import phone from '../../images/phone.svg'
import email from '../../images/email.svg'
import pattern from '../../images/ar-pattern.png'
import thankyou from '../../images/thankyou.svg'
import logoa from '../../images/s1.png'
import logob from '../../images/s2.png'
import logoc from '../../images/s3.png'
import logod from '../../images/s4.png'
import logoe from '../../images/s5.png'
const Contact = () => {
    const [formData, setFormData] = useState({
        email: '',
        desk: '',
    });


    const [errors, setErrors] = useState({});
    const form = useRef();

    const [showForm, setShowForm] = useState(true)
    const [showThankyou, setShowThankyou] = useState(false)
    function showError(inputName, message) {
        setErrors((prevErrors) => ({
            ...prevErrors,
            [inputName]: message,
        }));
    }
    const handleChange = (event) => {
        const { name, value } = event.target;
        setFormData((prevData) => ({
            ...prevData,
            [name]: value
        }));
        showError(name, '');
    };

    const handleSubmit = (event) => {
        event.preventDefault();
        const validationErrors = validateForm(formData);
        console.log(Object.keys(validationErrors).length)
        if (Object.keys(validationErrors).length === 0) {
            setShowThankyou(true)
            setShowForm(false)
            console.log('Form is valid ')
            const config = {
                SecureToken: 'f6d6942c-d90a-488c-9de2-7da480ccc106',
                To: 'info@idrakai.com',
                From: "info@idrakai.com",
                Subject: 'SCHEDULE',
                Body: `Email:  ${formData.email}, 
                        MESSAGE:  ${formData.desk}`
            }
            window.Email.send(config).then((e, c, p, d) => console.log(e, c, p, d))
        } else {
            setErrors(validationErrors);
        }
    };

    const validateForm = (data) => {
        const errors = {};

        if (!data.email) {
            errors.email = 'Email is required*';
        } else if (!/\S+@\S+\.\S+/.test(data.email)) {
            errors.email = 'Invalid email format*';
        }

        return errors;
    };
    return (
        <>
            <div className='AR-logo'>
                <div className="AR-logo-subI">
                    <img src={logoa} alt='' />
                    <img src={logob} alt='' />
                    <img src={logoc} alt='' />
                    <img src={logod} alt='' />
                    <img src={logoe} alt='' />
                    <img src={logoa} alt='' />
                    <img src={logob} alt='' />
                    <img src={logoc} alt='' />
                    <img src={logod} alt='' />
                    <img src={logoe} alt='' />
                    <img src={logoa} alt='' />
                    <img src={logob} alt='' />
                    <img src={logoc} alt='' />
                    <img src={logod} alt='' />
                    <img src={logoe} alt='' />
                    <img src={logoa} alt='' />
                    <img src={logob} alt='' />
                    <img src={logoc} alt='' />
                    <img src={logod} alt='' />
                    <img src={logoe} alt='' />
                    <img src={logoa} alt='' />
                    <img src={logob} alt='' />
                    <img src={logoc} alt='' />
                    <img src={logod} alt='' />
                    <img src={logoe} alt='' />
                    <img src={logoa} alt='' />
                    <img src={logob} alt='' />
                    <img src={logoc} alt='' />
                    <img src={logod} alt='' />
                    <img src={logoe} alt='' />
                </div>
                <div className="AR-logo-subII">
                    <img src={logoa} alt='' />
                    <img src={logob} alt='' />
                    <img src={logoc} alt='' />
                    <img src={logod} alt='' />
                    <img src={logoe} alt='' />
                    <img src={logoa} alt='' />
                    <img src={logob} alt='' />
                    <img src={logoc} alt='' />
                    <img src={logod} alt='' />
                    <img src={logoe} alt='' />
                    <img src={logoa} alt='' />
                    <img src={logob} alt='' />
                    <img src={logoc} alt='' />
                    <img src={logod} alt='' />
                    <img src={logoe} alt='' />
                    <img src={logoa} alt='' />
                    <img src={logob} alt='' />
                    <img src={logoc} alt='' />
                    <img src={logod} alt='' />
                    <img src={logoe} alt='' />
                    <img src={logoa} alt='' />
                    <img src={logob} alt='' />
                    <img src={logoc} alt='' />
                    <img src={logod} alt='' />
                    <img src={logoe} alt='' />
                    <img src={logoa} alt='' />
                    <img src={logob} alt='' />
                    <img src={logoc} alt='' />
                    <img src={logod} alt='' />
                    <img src={logoe} alt='' />
                    <img src={logoa} alt='' />
                    <img src={logob} alt='' />
                    <img src={logoc} alt='' />
                    <img src={logod} alt='' />
                    <img src={logoe} alt='' />
                    <img src={logoa} alt='' />
                    <img src={logob} alt='' />
                    <img src={logoc} alt='' />
                    <img src={logod} alt='' />
                    <img src={logoe} alt='' />
                    <img src={logoa} alt='' />
                    <img src={logob} alt='' />
                    <img src={logoc} alt='' />
                    <img src={logod} alt='' />
                    <img src={logoe} alt='' />
                    <img src={logoa} alt='' />
                    <img src={logob} alt='' />
                    <img src={logoc} alt='' />
                    <img src={logod} alt='' />
                    <img src={logoe} alt='' />
                    <img src={logoa} alt='' />
                    <img src={logob} alt='' />
                    <img src={logoc} alt='' />
                    <img src={logod} alt='' />
                    <img src={logoe} alt='' />
                    <img src={logoa} alt='' />
                    <img src={logob} alt='' />
                    <img src={logoc} alt='' />
                    <img src={logod} alt='' />
                    <img src={logoe} alt='' />

                </div>
            </div>

            <div className="contact-wrapper" id='Contact'>
                <div className="gradient-contact"></div>
                <div className="inner-left">
                    <div className="inner-heading">
                        <h1>Let's Talk...</h1>
                        <p>Take the Next Step Towards Innovation</p>
                    </div>
                    <div className="contact-info">
                        <div className="phone-no">
                            <div className='phone'>
                                <img src={phone} alt=""/>
                                <span className='Phone-num'>Phone num</span>
                            </div>
                            <div className='number'><a href="tel:+447771743077" style={{ textDecoration: 'none', color: 'white' }}>+447771743077</a>
                            </div>
                        </div>
                        <div className="email-id">
                            <div className='email'>
                                <img src={email} alt=""/>
                                <span className='Email-ID'>Email</span>
                            </div>
                            <div className='-email'><a href="mailto:info@idrakai.com" style={{ textDecoration: 'none', color: 'white' }}>info@idrakai.com</a></div>
                        </div>
                    </div>
                    <div className='address'>
                        <a href="https://goo.gl/maps/it7VmfaJVet1JdR18" style={{ textDecoration: 'none', color: 'white' }}>
                            71-75 Shelton Street Covent Garden, London WC2H 9JQ, United Kingdom
                        </a>
                    </div>
                </div>
                <div className="inner-right">
                    <img src={pattern} className='pattern-contact'  alt=""/>
                    {showForm && (
                        <form ref={form} onSubmit={handleSubmit}>
                            <div className='form-email'>
                                <label htmlFor="Email">Email</label>
                                {errors.email && <span className="error">{errors.email}</span>}
                                <input
                                    type="text"
                                    name="email"
                                    value={formData.email}
                                    onChange={handleChange}
                                    required
                                />
                            </div>
                            <div className='form-message'>
                                <label htmlFor="Message">Message</label>
                                {errors.desk && <span className="error">{errors.desk}</span>}
                                <input
                                    type="text"
                                    name="desk"
                                    value={formData.desk}
                                    onChange={handleChange}
                                    required
                                />
                            </div>
                            <button onClick={handleSubmit}>Send</button>
                        </form>
                    )}
                    {showThankyou && (
                        <div className='thankyouwrapper'>
                            <div className='thankyoumessage'>
                                <img src={thankyou} alt="" />
                                <h1>Thank you!</h1>
                                <p>Your submission has been recieved</p>
                            </div>
                        </div>
                    )}
                </div>
            </div>
            <div className='bottom-bar'>
                <a href='/Privacy'>Privacy Policy</a>
                <a href='/Terms'>Terms of Use</a>
            </div>
        </>
    )
}

export default Contact