import React from 'react'
import '../node_modules/bootstrap/dist/css/bootstrap.min.css';
import '../node_modules/bootstrap/dist/js/bootstrap.bundle';
import Home from './Pages/Home/Home'

import { BrowserRouter as Router, Routes, Route, createBrowserRouter, RouterProvider } from "react-router-dom";
import Navbar from './Components/Navbar/Navbar'
import Terms from './Components/Terms/Terms';
import Privacy from './Components/Terms/Privacy'
import Root from './Components/root/root';
const App = () => {
  const router = createBrowserRouter([
    {
      path: '/',
      element: <Root />,
      children:
        [{
          path: "/",
          element: (
            <Home />
          ),
        },
        {
          path: "/Terms",
          element: (
            <Terms />
          ),
        },
        {
          path: "/Privacy",
          element: (
            <Privacy />
          ),
        }]
    }
  ])
  return (
    <>
      <RouterProvider router={router} />
    </>
  )
}

export default App
